import React, { Component, Suspense, lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
 

const Signin = lazy(() => import('./general-pages/Signin')) 
const ViewArticle = lazy(() => import('./general-pages/ViewArticles'))
const AddArticle = lazy(() => import('./general-pages/AddArticle'))
const AddAd = lazy(() => import('./general-pages/AddAd'))
const ViewAds = lazy(() => import('./general-pages/ViewAds'))
const ViewMemberships = lazy(() => import('./general-pages/ViewMemberships'))
const AddMembership = lazy(() => import('./general-pages/AddMembership'))
const ViewOrganizations = lazy(() => import('./general-pages/ViewOrganizations'))
const AddOrganization = lazy(() => import('./general-pages/AddOrganization'))

 
const PageError = lazy(() => import('./general-pages/Page-404'))


export class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback=''>
        <Switch>
          <Route exact path="/">
            <Redirect to="/general-pages/signin"></Redirect>
          </Route> 
          <Route exact path="/general-pages/ViewArticles" component={ ViewArticle } />

          <Route exact path="/general-pages/signin" component={ Signin } /> 
          <Route exact path="/general-pages/page-404" component={ PageError } />
          <Route exact path="/general-pages/AddArticle" component={ AddArticle } />
          <Route exact path="/general-pages/AddAd" component={ AddAd} />
          <Route exact path="/general-pages/ViewAds" component={ ViewAds} />
          <Route exact path="/general-pages/AddMembership" component={ AddMembership} />
          <Route exact path="/general-pages/ViewMemberships" component={ ViewMemberships} />
          <Route exact path="/general-pages/AddOrganization" component={ AddOrganization} />
          <Route exact path="/general-pages/ViewOrganizations" component={ ViewOrganizations} /> 

        </Switch>
      </Suspense>
    )
  }
}

export default AppRoutes
