import React, { Component,useContext } from 'react';
import { UserContext } from '../context/UserContext';
//export class Footer extends Component {
  
  export const Footer =()=>{
     
    const user = useContext(UserContext);
    return (
    
      <div>
        <div className="az-footer ht-40">
          <div className="container ht-100p pd-t-0-f">
            <div className="d-sm-flex justify-content-center justify-content-sm-between py-2 w-100">
              {user}
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © Bertucan 2020</span>
          </div>
          </div>{/* container */}
        </div>{/* az-footer */}
      </div>
       
    )
 

}

export default Footer
